





















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  components: {
    InsCmsPhotoSwiper: () => import('@/components/business/cms/InsCmsPhotoSwiper.vue')
  }
})
export default class InsCmsCat6 extends Vue {
  cmsPhotoList: object[] = [];
  showPhotoList: boolean = false;
  photoIndex: number = 0;
  page: string = '60th';

  getPromotion () {
    this.$Api.promotion.GetPromotionByDevice({ Page: this.page, Position: 4, IsMobile: this.isMobile, BannerEnable: true }).then((result) => {
      this.cmsPhotoList = result.Promotion.BannerList;
      console.log(this.cmsPhotoList, 'cms内文相册1');
    }).catch((error) => {
      console.log(error, 'error');
    });
  }
  // 点击某张相片
  clickOne (i) {
    this.showPhotoList = true;
    this.photoIndex = i;
  }
  showSwiper (sta) {
    this.showPhotoList = sta;
  }
  get isMobile () {
    return this.$store.state.isMobile;
  }
  get id () {
    return this.$route.params.id;
  }
  mounted () {
    if (this.id === '40147') {
      this.getPromotion();
    }
  }
  @Watch('id', { deep: true })
  onKeyChange () {
    if (this.id === '40147') {
      this.getPromotion();
    }
  }
}
